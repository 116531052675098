import colors from './foundations/colors';
import shadows from './foundations/shadows';
import layerStyles from './foundations/layerStyles';
import global from './foundations/global';
import Tag from './components/tag';
import Heading from './components/heading';
import Text from './components/text';
import Button from './components/button';
import Link from './components/link';
import Menu from './components/menu';
import Container from './components/container';
import Accordion from './components/accordion';
import List from './components/list';
import Slider from './components/slider';
import Tooltip from './components/tooltip';
import Input from './components/input';
import Select from './components/select';

export const theme = {
  colors,
  shadows,
  layerStyles,
  components: {
    Tag,
    Heading,
    Text,
    Button,
    Link,
    List,
    Container,
    Menu,
    Accordion,
    Slider,
    Tooltip,
    Input,
    Select,
  },
  fonts: {
    heading: '"Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    body: '"Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    reading: '"PT Serif", serif',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '52px',
    '7xl': '64px',
  },
  breakpoints: {
    tablet: 700,
    desktop: 1000,
    large: 1280,
  },
  styles: {
    global,
  },
};
